var axios = require('axios');

var urlSocket = axios.create({

  /* LIVE URL*/
   baseURL: 'https://tcstestapi.pentabrains.com/api/',

   //baseURL: 'http://pboffice.pentabrains.com/api/',
    // headers: {
    //   "Access-Control-Allow-Origin": "*",
    //   "Content-Type": "application/json",
    //   "Accept": 'Application/json',
    // },
  timeout: 0,


})

module.exports = urlSocket;