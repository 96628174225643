import React, { Component } from "react";
import PropTypes from "prop-types";
import { Alert, Card, CardBody, Col, Container, Row, Label } from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// action
import {
  apiError,
  registerUser,
  registerUserFailed,
} from "../../store/actions";

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// import images
//import profileImg from "../../assets/images/profile-img.png";
import profileImg from "../../assets/sktm_images/logo_1.png";
import logoImg from "../../assets/images/logo.svg";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email:"",
      password:"",
      confirmPassword:"",
      mobileNumber:"",
      username:"",
      lastname:"",

    };
  }

  componentDidMount() {
    this.props.apiError("");
    this.props.registerUserFailed("");
  }

  validateEmail =()=>{
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const phoneRegex =  /^[0]?[123456789]\d{9}$/;

    var isValidEmail = emailRegex.test(this.state.email);

    return isValidEmail
  }

  validatePhoneNumber =()=>{
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const phoneRegex =  /^[0]?[123456789]\d{9}$/;

    var isValidPhoneNumber = phoneRegex.test(this.state.mobileNumber);

    console.log("isValidPhoneNumber", isValidPhoneNumber)

    return isValidPhoneNumber
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={12} xl={12}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-12 d-flex justify-content-center">
                        <div
                          style={{ width: 300, height: "auto", padding: 30 }}
                        >
                          <img src={profileImg} alt="" className="img-fluid" />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="text-primary py-4">
                      <h5 className="text-primary">Register</h5>
                      <p>Create your account here.</p>
                    </div>

                    <div className="p-2">
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          email: (this.state && this.state.email) || "",
                          password: (this.state && this.state.password) || "",
                          username: (this.state && this.state.username) || "",
                          mobileNumber: (this.state && this.state.mobileNumber) || "",
                          confirmPassword: (this.state && this.state.confirmPassword) || "" ,
                        }}
                        validationSchema={Yup.object().shape({
                          email: Yup.string().required(
                            "Please Enter Valid Email"
                          ),
                          password: Yup.string().required(
                            "Please Enter Valid Password"
                          ),
                          username: Yup.string().required(
                            "Please Enter Valid name"
                          ),
                          mobileNumber: Yup.string()
                            .min(10, "Must be exactly 10 digits")
                            .max(10, "Must be exactly 10 digits")
                            .required("Min 10 chars"),
                          confirmPassword: Yup.string().required(
                            "Please Enter Confirm Password"
                          ),
                        })}
                        onSubmit={(values) => {
                          console.log(values)
                          //this.props.registerUser(values);
                        }}
                      >
                        {({ errors, status, touched }) => (
                          <Form className="form-horizontal">
                            <Row>
                              <Col className="col-6">
                                <div className="mb-3">
                                  <Label for="username" className="form-label">
                                    First name
                                  </Label>
                                  <Field
                                    name="username"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.username && touched.username
                                        ? " is-invalid"
                                        : "")
                                    }
                                    value={this.state.username}
                                    onChange={(event)=>{
                                      this.setState({username:event.target.value})
                                    }}
                                  />
                                  <ErrorMessage
                                    name="username"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                              <Col className="col-6">
                                <div className="mb-3">
                                  <Label for="lastname" className="form-label">
                                    Last name
                                  </Label>
                                  <Field
                                    name="lastname"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.lastname && touched.lastname
                                        ? " is-invalid"
                                        : "")
                                    }
                                    value={this.state.lastname}
                                    onChange={(event)=>{
                                      this.setState({lastname:event.target.value})
                                    }}
                                  />
                                  <ErrorMessage
                                    name="lastname"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col className="col-6">
                                <div className="mb-3">
                                  <Label for="email" className="form-label">
                                    Email
                                  </Label>
                                  <Field
                                    name="email"
                                    type="email"
                                    className={
                                      "form-control" +
                                      (errors.email && touched.email
                                        ? " is-invalid"
                                        : "")
                                    }
                                    value={this.state.email}
                                    onChange={(event)=>{
                                      this.setState({email:event.target.value})
                                    }}
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                              <Col className="col-6">
                                <div className="mb-3">
                                  <Label
                                    for="mobileNumber"
                                    className="form-label"
                                  >
                                    Mobile Number
                                  </Label>
                                  <Field
                                    name="mobileNumber"
                                    type="number"
                                    className={
                                      "form-control" +
                                      (errors.mobileNumber && touched.mobileNumber
                                        ? " is-invalid"
                                        : "")
                                    }
                                    value={this.state.mobileNumber}
                                    onChange={(event)=>{
                                      this.setState({mobileNumber:event.target.value})
                                    }}
                                  />
                                  <ErrorMessage
                                    name="mobileNumber"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col className="col-6">
                                <div className="mb-3">
                                  <Label for="password" className="form-label">
                                    Password
                                  </Label>
                                  <Field
                                    name="password"
                                    autoComplete="true"
                                    type="password"
                                    className={
                                      "form-control" +
                                      (errors.password && touched.password
                                        ? " is-invalid"
                                        : "")
                                    }
                                    value={this.state.password}
                                    onChange={(event)=>{
                                      this.setState({password:event.target.value})
                                    }}
                                  />
                                  <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                              <Col className="col-6">
                                <div className="mb-3">
                                  <Label
                                    for="confirmPassword"
                                    className="form-label"
                                  >
                                    Confirm Password
                                  </Label>
                                  <Field
                                    name="confirmPassword"
                                    autoComplete="true"
                                    type="password"
                                    className={
                                      "form-control" +
                                      (errors.confirmPassword && touched.confirmPassword
                                        ? " is-invalid"
                                        : "")
                                    }
                                    value={this.state.confirmPassword}
                                    onChange={(event)=>{
                                      this.setState({confirmPassword:event.target.value})
                                    }}
                                  />
                                  <ErrorMessage
                                    name="confirmPassword"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                            </Row>

                            <div className="mt-4 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                Register
                              </button>
                            </div>

                            <div className="mt-4 text-center">
                              <p className="mb-0">
                                By registering you are agree to the Swaranadharaa Quiq Pay{" "}
                                <Link to="#" className="text-primary">
                                  Terms and Conditions of Use
                                </Link>
                              </p>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Already have an account ?{" "}
                    <Link to="/login" className="fw-medium text-primary">
                      {" "}
                      Login
                    </Link>{" "}
                  </p>
                  <p>
                    © {new Date().getFullYear()} Swaranadharaa Quiq Pay. Powered by Pentabrains
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Register.propTypes = {
  apiError: PropTypes.any,
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.any,
  registrationError: PropTypes.any,
  user: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStateToProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register);
