import React, { Component } from "react";
import PropTypes from "prop-types";

import { Alert, Card, CardBody, Col, Container, Row, Label } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

//Import config
import { facebook, google } from "../../config";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// actions
import { apiError, loginUser, socialLogin } from "../../store/actions";

// import images
import profile from "../../assets/sktm_images/logo_1.png";
import logo from "../../assets/images/logo.svg";
import lightlogo from "../../assets/images/logo-light.svg";

var urlSocket = require('../../helpers/urlSocket');

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authValidated:"0",
      userInputDisabled:false,
      userValidation:false,
      setProgress:false
    };
  }

  componentDidMount() {
    this.props.apiError("");
  }

  validate = (values) => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  
    if (!values.email) {
      errors.email = "Email  is required";

      return errors
    
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const phoneRegex =  /^[0]?[123456789]\d{9}$/;

    var isValidPhoneNumber = phoneRegex.test(Number(values.email));
    var isValidEmail = emailRegex.test(values.email);


    if(!isValidEmail) 
    {
      errors.email = "Not valid email "
      return errors;
    }
    
  };

  validateAuthType =(values) =>{

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const phoneRegex =  /^[0]?[123456789]\d{9}$/;

    var isValidPhoneNumber = phoneRegex.test(Number(values.email));
    var isValidEmail = emailRegex.test(values.email);

    if(!this.state.userValidation)
    {
    //   if(isValidPhoneNumber)
    // {
    //   this.setState({authBy:"phoneNumber"},()=>{
    //     this.authenticateUser(values.email)
    //   })
      
    // }
    // else
    if(isValidEmail)
    {
      this.setState({authBy:"email"}, () => {
        this.authenticateUser(values.email)
      })
      
    }
    }
    else
    {
      // if(isValidPhoneNumber)
      // {
      //   this.validateOTP(values.otp,values.email)
      // }
      // else 
      if(isValidEmail)
      {
        this.validateEmailOTP(values.password,values.email)
      }
      
    }
    

    //return "Enter Valid Email / Phone number"

    
  }

  authenticateUser = (emailorNumber) =>{
    this.setState({setProgress:true})

    if(this.state.authBy === "email")
    {
    try {
      urlSocket.post("Auth/EmailValidation", {
        username: emailorNumber,
        password: "",
      })
        .then((response) => {
          console.log("response", response)
          
          this.setState({setProgress:false})
          if(response.data.statusCode === "001")
          {
            this.setState({userInputDisabled:true, userValidation:true, authValidated:"1", })
          }
        })
        .catch(error => {
          console.log("error", error)
          this.setState({setProgress:false})
        })
    } catch (error) {
      
    }
  }
  else
  if(this.state.authBy === "phoneNumber")
  {
    try {
      urlSocket.post("Auth/MobileValidation", {
        mobileNo: emailorNumber,
      })
        .then((response) => {
          console.log("response", response)
          var data = {
            "aU_PK_ID": 4523,
            "aU_USERNAME_VAR": "Login.suresh@gmail.com",
            "aU_PASSWORD_HASH_VAR": "MVzmCLbRKSI/11qQpOnckSMhchLJA1ZhGDWP2fpoEjA=",
            "aU_PASSWORD_SALT_VAR": "Id8CJo1o7bA1SA4qtMFslA==",
            "aU_EMAILID_VAR": "Login.suresh@gmail.com",
            "aU_MOBILE_NO_VAR": "9790703505",
            "aU_FIRSTNAME_VAR": "Suresh kumar",
            "aU_LASTNAME_VAR": "G",
            "aU_CREATEDDATE_DATETIME": "2022-06-14T15:31:04.263",
            "aU_OLD_PASSWORD_HASH_VAR": "MVzmCLbRKSI/11qQpOnckSMhchLJA1ZhGDWP2fpoEjA=",
            "aU_OLD_PASSWORD_SALT_VAR": "Id8CJo1o7bA1SA4qtMFslA==",
            "aU_MODIFIED_DATETIME": "2022-06-14T15:31:04.263",
            "aU_ISACTIVE_BIT": true,
            "aU_LASTLOGIN_DATETIME": "2022-12-16T12:27:09.894677+05:30",
            "aU_SIGNIN_STATUS": true,
            "aU_ISPROFILE_UPDATED": true,
            "aU_IDPHONENO_VERIFIED": true
          }
          localStorage.setItem("authUser",JSON.stringify(data))
          this.setState({setProgress:false})
          if(response.data.statusCode === "001")
          {
            this.setState({userInputDisabled:true, userValidation:true, authValidated:"1"})
          }
        })
        .catch(error => {
          console.log("error", error)
          this.setState({setProgress:false})
        })
    } catch (error) {
      
    }
  }
  }

  validateOTP = (OTPText, emailorNumber) =>{
    console.log("OTP---", OTPText, emailorNumber)
    this.setState({setProgress:true})
    try {
      urlSocket.post("Auth/OTPValidation", {
        otp: String(OTPText),
        mobileNo: String(emailorNumber),
        email:""
      }).then( (response) => {
        console.log("response", response.data)
        this.setState({setProgress:false})
        if(response.data.statusCode === "001")
          {
            this.props.history.push("/dashboard")
          }
          else{
            this.props.history.push("/dashboard")
          }
      })
      .catch(error => {
        console.log("catch error", error)
        this.setState({setProgress:false})
      })
    } catch (error) {
      
    }
  }
  validateEmailOTP = (secureText, emailorNumber) =>{
    this.setState({setProgress:true})
    console.log("OTP---", secureText, emailorNumber)
    try {
      urlSocket.post("Auth/authenticate", {
        username: emailorNumber,
        password: secureText,
      })
      .then( (response) => {
        console.log("response", response)
        
        this.setState({setProgress:false})
        if(response.data.statusCode === "001")
          {
            localStorage.setItem("authUser",JSON.stringify(response.data.data))
            this.props.history.push("/dashboard")
          }
          else{
            this.props.history.push("/dashboard")
          }
      })
      .catch(error => {
        console.log("catch error", error)
        this.setState({setProgress:false})
      })
    } catch (error) {
      
    }
  }


  Auth = () => {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          email: "",
          password: "",
        }}
        validate={this.validate}
        onSubmit={this.validateAuthType}
      >
        {({ errors, status, touched }) => (
          <Form className="form-horizontal">
            <div className="mb-3">
              <Label for="email" className="form-label">
                Email
              </Label>
              <Field
                name="email"
                type="text"
                disabled={this.state.authValidated === "1"}
                className={
                  "form-control" +
                  (errors.email && touched.email ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="email"
                component="div"
                className="invalid-feedback"
              />
            </div>
            {this.state.authValidated === "1" &&
            this.state.authBy === "email" ? (
              <div className="mb-3">
                <Label for="password" className="form-label">
                  Password
                </Label>
                <div className="input-group auth-pass-inputgroup">
                  <Field
                    name="password"
                    type="password"
                    autoComplete="true"
                    className={
                      "form-control" +
                      (errors.password && touched.password ? " is-invalid" : "")
                    }
                  />
                  <button
                    className="btn btn-light "
                    type="button"
                    id="password-addon"
                  >
                    <i className="mdi mdi-eye-outline"></i>
                  </button>
                </div>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            ) : this.state.authValidated === "1" &&
            this.state.authBy === "phoneNumber" ?
            <div className="mb-3">
            <Label for="otp" className="form-label">
              OTP
            </Label>
            <div className="input-group auth-pass-inputgroup">
              <Field
                name="otp"
                type="text"
                autoComplete="true"
                className={
                  "form-control" +
                  (errors.password && touched.password ? " is-invalid" : "")
                }
              />
              
            </div>
            <ErrorMessage
              name="otp"
              component="div"
              className="invalid-feedback"
            />
          </div>:null }

            <div className="mt-3 d-grid">
              <button className="btn btn-primary btn-block" type="submit" disabled={this.state.setProgress}>
                Submit
              </button>
            </div>

            {/* <div className="mt-4 text-center">
              <Link to="/forgot-password" className="text-muted">
                <i className="mdi mdi-lock me-1" /> Forgot your password?
              </Link>
            </div> */}
          </Form>
        )}
      </Formik>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-12 d-flex justify-content-center">
                        <div
                          style={{ width: 300, height: "auto", padding: 30 }}
                        >
                          <img src={profile} alt="" className="img-fluid" />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    
                    <div className="p-2 mt-3">
                      {this.props.error && this.props.error ? (
                        <Alert color="danger">{this.props.error}</Alert>
                      ) : null}
                      { this.Auth()}
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Don&apos;t have an account ?{" "}
                    <Link to="register" className="fw-medium text-primary">
                      Signup Now
                    </Link>
                  </p>
                  <p>
                    © {new Date().getFullYear()} Swaranadharaa Quiq Pay. Powered
                    by Pentabrains
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
);
