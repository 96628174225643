import React, { Component } from "react";
import { Container } from "reactstrap";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

class PrivacyPolicy extends Component {
  render() {
    //meta title
    document.title = "Starter Page | Skote - React Admin & Dashboard Template";
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="" breadcrumbItem="Privacy and Policy" />

            <Row className="align-items-center">
              <Col lg="12">
                <div className="text-muted">
                  <p>
                    Protection and security of your personal Information is one
                    of Sree Kumaran Thangamaligai top priorities. This privacy
                    Policy disclosures Sree Kumaran Thangamaligai practice with
                    respect to the Information collected from the users of this
                    website/mobile app. By using this website/ mobile app you
                    agree to accept the terms of this Privacy Policy as well as
                    the website/ mobile app’s Terms of Use. By accessing or
                    using this website/mobile app you expressly consent to use
                    our website/ Mobile app and disclosure of your personal
                    Information in any manner described in this Privacy Policy.
                    This Privacy Policy extends to both, users who visit the
                    website/ mobile app but do not transact business on the
                    websites as well as users who are registered on the website.
                    “Personal Information” refers to any information that
                    identifies or can be used to identify, contact or locate the
                    person to whom such information pertains including, but not
                    limited to name, address, phone number and email address.{" "}
                    {"\n"}
                    {"\n"} <br /> <br />
                    <strong>
                      PLEASE READ THE FOLLOWING TERMS OF OUR PRIVACY POLICY ON
                      PERSONAL INFORMATION COLLECTED
                    </strong>{" "}
                    {"\n"} <br /> <br /> By accepting this Privacy Policy, you
                    authorize Sree Kumaran Thangamaligai to collect, store and
                    use any information that you provide on our website/ mobile
                    app. The Information collected by us are all information
                    entered by you on our website/ mobile app such as your name,
                    address, contact number, email ID and such other information
                    sent by you via emails to our email id. {"\n"}
                    {"\n"}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default PrivacyPolicy;
