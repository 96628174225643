import AppMode from "./appMode";

let RPCode = {
  getKeyCode: function () {
    //-------- development ---------------
    if (AppMode.getMode() === "1") {
      var rp_code = {
        key: "rzp_test_4kW6qxPx4ZRXzN",
        security: "Iiw3JDJQGyNCgfAAyTeQMyEh",
      };
    }

    //-------- production ---------------
    if (AppMode.getMode() === "2") {
      var rp_code = {
        key: "rzp_live_fEBgsCMYzRadTO",
        security: "kYXVhE8aRvhSaTG91KLT1fwC",
      };
    }

    //-------- Testing ---------------
    if (AppMode.getMode() === "3") {
      var rp_code = {
        key: "rzp_live_fEBgsCMYzRadTO",
        security: "kYXVhE8aRvhSaTG91KLT1fwC",
      };
    }

    return rp_code;
  },
};

export default RPCode;
