import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Badge,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  FormGroup,
  Form,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import { propTypes } from "react-bootstrap-editable";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isEmpty, size } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";
import base64 from "react-native-base64";

import URLSocket from "../../helpers/urlSocket";
import RPCode from "../../helpers/rpcode";
import useRazorpay from "react-razorpay";

import LatestTranaction from "./Components/chitSummary";

import moment from "moment";
var axios = require('axios');

const SchemeDetails = (props) => {

  
  //console.log("scheme", scheme);

  const [showAlert, setAlert] = React.useState(false);
  const [fetchSummary, setFetchSummary] = React.useState([]);
  const [schemeSummary, setSchemeSummary] = React.useState([]);
  const [dataFetching, setDataFetching] = React.useState(true);
  const [showSummary, setShowSummary] = React.useState(false);

  const [showModal, setShowModal] = React.useState(false);
  const [showPaymentModal, setPaymentModal] = React.useState(false);
  const [paymentModeType, setPaymentModeType] = React.useState();
  const [checked, setChecked] = React.useState("Online");
  const [transactionDeclinedData, setTD] = React.useState({});
  const [noRecordFound, setRecordFound] = React.useState(false);
  const [showReceipt, setshowReceipt] = React.useState(false);
  const [receiptData, setReceiptData] = React.useState(false);
  const [enableTimeout, setTimeoutEnabled] = React.useState(false);
  const [rpKey, setRPKey] = React.useState(RPCode.getKeyCode())
  const [error, setError] = React.useState({
    message: "",
    isError: false,
  });

  const [myUserInfo, setUserInfo] = useState("")
  const [scheme, setSchemeInfo]  = useState("")

  const Razorpay = useRazorpay();

  useEffect(() => {
    var userInformation = JSON.parse(localStorage.getItem("authUser"))
    console.log("user", userInformation)
    setUserInfo(userInformation)
    
    var getSchemeInfo = JSON.parse(localStorage.getItem("schemeInfo"))
    console.log("getSchemeInfo", getSchemeInfo)
    setSchemeInfo(getSchemeInfo)
  }, []);

  useEffect(() => {
    setAlert(false)
    setDataFetching(true);

    //getUserInfo()
    //checkPayment("order_K9YLsDdAxPDlno")
    if(scheme !== "")
    {
      setFetchSummary([]);
      setSchemeSummary([]);
      setShowSummary(false)
        refreshSummary();
  
    }


  }, [scheme]);

  const refreshSummary = async () => {
    setRecordFound(false);
    try {
      URLSocket.post("Scheme/FetchChitDetails", {
        mobileNo: scheme.mobileno,
        location: scheme.locid,
        accountNo: scheme.accno,
        chitMemberID: scheme.chitmemberid,
      }).then((response) => {
        if (response.data.statusCode === "001") {
          console.log("response.data", response.data)
          var data = response.data.data;
          if (response.data.data.length > 0) {
            setFetchSummary(data);
            createSchemeSummary(data);
          } else {
            setDataFetching(false);
            setRecordFound(true);
          }
        } else {
          setError({ isError: true, message: "No Records found" });
        }
      })
      .catch(error => {
        setError({ isError: true, message: "Something went wrong. Please try after sometime." });
      })
    } catch (error) {
      console.log("catch error", error);
    }
  };

  const onCancelAlert = () => {
    setAlert(false)
  }

  const createSchemeSummary = (info) => {
    console.log("am in")
    let schmSummary = [];
    var getCurrentYear = new Date(scheme.joineddate).getFullYear();
    var getMonth = new Date(scheme.joineddate).getMonth();
    

    var m = getMonth + 1;

    for (var i = 0; i < scheme.chI_Noof_Installments_Int; i++) {
      getCurrentYear = m > 12 ? getCurrentYear + 1 : getCurrentYear;
      m = m > 12 ? 1 : m;
      var changeMonth = m;

      schmSummary.push({
        installment: String(i + 1).length > 1 ? i + 1 : "0" + String(i + 1),
        month:
          i === 0
            ? moment(scheme.joineddate).format("DD/MM/YYYY")
            : "15/" + String(changeMonth) + "/" + getCurrentYear,
        amount: scheme.chI_InstallmentAmount_Int,
        paid_on: scheme.paidon,
        monthNumber: m,
        payment_status: "2",
        chitmemberid: scheme.chitmemberid,
        customerid: scheme.customerid,
        accno: scheme.accno,
        chitmasterid: 0,
        mobileno: "string",
        branch: scheme.branch,
        locid: scheme.locid,
        accname: scheme.accname,
        paidon: scheme.paidon,
        joineddate: scheme.joineddate,
        totalamount: scheme.totalamount,
        chR_Due_Installment_No_int: i + 1,
        chI_Noof_Installments_Int: scheme.chI_Noof_Installments_Int,
        chR_CollectedAmount_num: 0,
        chI_InstallmentAmount_Int: scheme.chI_InstallmentAmount_Int,
        status: scheme.status,
        chittype: scheme.chittype,
        recHeaderID: "",
        goldrate:0,
        transaction: null,
        paidOnDate:null,
        weight:"",
        ipAddress: "127.0.0.1",
        receiptDate:""
      });
      m++;
    }
    
    var itemMonthPaid = 1
    var isPaymentPaidForThisMonth = false
    setTimeout(async() => {
      var getMonth = new Date().getMonth() + 1;
      _.each(schmSummary, (item, i) => {
        //itemMonthPaid = i+1
        var data = _.find(info, { chR_Due_Installment_No_int: i + 1 });
        if (data !== undefined || data != null) {

          item["installment"] = item.installment;
          item["month"] = item.month;
          item["amount"] = item.amount;
          item["paid_on"] = data.paidon;
          item["recHeaderID"] = data.recHeaderID;
          item["weight"] = data.weight;
          item["goldrate"] = data.goldrate;
          item["payment_status"] = "1";
          item["paidOnDate"] = data.painoN_TIMESTAMP

          itemMonthPaid = data.chR_Due_Installment_No_int
          var transactionForThisMonth = moment(data.painoN_TIMESTAMP).isSame(new Date(), 'month');
          var transactionForThisMonthofYear = moment(data.painoN_TIMESTAMP).isSame(new Date(), 'year');


          if(transactionForThisMonth && transactionForThisMonthofYear)
          {
            isPaymentPaidForThisMonth = true
          }
          else
          {
            isPaymentPaidForThisMonth = false
          }
        }

      });
      
      if(!isPaymentPaidForThisMonth && itemMonthPaid <= scheme.chI_Noof_Installments_Int)
      {
        schmSummary[itemMonthPaid]["payment_status"] = "0"        
        var value = await (localStorage.getItem('@paymentInitTime:key'))
        value = JSON.parse(value)
        var myAccount = _.filter(value, {"accNo":String(scheme.accno)})[0]
            if(value !== null && myAccount !== undefined)
            {
              var startTime = moment(myAccount.date); //todays date
              var end = moment(new Date());
              var duration = moment.duration(end.diff(startTime));
              var minutes = duration.asMinutes();
              if(minutes <= 15 && schmSummary[itemMonthPaid]["accno"] === myAccount.accNo)
              {
                schmSummary[itemMonthPaid]["disabled"] = false
              }
              else{
                schmSummary[itemMonthPaid]["disabled"] = false
              }
            }
            else
            {
              schmSummary[itemMonthPaid]["disabled"] = false
            }
      }
      else
      {
        schmSummary[itemMonthPaid]["disabled"] = false
      }
      console.log("schmSummary", schmSummary)
      setSchemeSummary(schmSummary);
      setDataFetching(false);
      setShowSummary(true)
    }, 500);
  };

  const paymentMode = (data, mode) => {
   // setShowModal(true);
    setPaymentModeType(mode);
    if (mode === "Online") {
      console.log("-------- Online payment mode selected ---------------")
      submitInfo(data, mode);
    }
  };

  const storeData = async (accNo, month) => {
    try {
      let data = {
        accNo: accNo,
        month: month,
        status: "0",
        date: String(new Date())
      }
      var getStorage =  (localStorage.getItem('@paymentInitTime:key'))
      getStorage = JSON.parse(getStorage)
      if (getStorage !== null) {
        let getIndex = _.findIndex(getStorage, { "accNo": data.accNo })
        if (getIndex !== -1) {
          getStorage.splice(getIndex, 1)
        }
      }
      else
      {
        getStorage = []
      }
      
      getStorage.push(data)
       localStorage.setItem('@paymentInitTime:key', JSON.stringify(getStorage))
    } catch (e) {
      console.log("catch error", e)
    }
  }

  const removeDataFromAsyncStorage = async(accNo) => {
    var getStorage = (localStorage.getItem('@paymentInitTime:key'))
      getStorage = JSON.parse(getStorage)
      if(getStorage !== null)
      {
        let getIndex = _.findIndex(getStorage, { "accNo": accNo })
        if (getIndex !== -1) {
          getStorage.splice(getIndex, 1)
        }
      }
      localStorage.setItem('@paymentInitTime:key', JSON.stringify(getStorage))
  }

  const submitInfo = (monthInfo, mode) => {
    if (mode === "Online") {
     // setTimeoutEnabled(false)
     // setShowModal(true);
      try {
        console.log("--- Creating Order ")
        var subSchemeInfo = scheme;
          URLSocket.post("Scheme/PayInstallmentOrderCreate", {
          "amount": String(subSchemeInfo.chI_InstallmentAmount_Int)+"00" ,
          "chitmemberid": scheme.chitmemberid,
          "customerid": scheme.customerid,
          "accno": scheme.accno,
          "chitmasterid": 0,
          "mobileno": String(myUserInfo.aU_MOBILE_NO_VAR),
          "locid": Number(subSchemeInfo.locid),
          "accname": subSchemeInfo.accname,
          "joineddate": scheme.joineddate,
          "totalamount": Number(scheme.totalamount),
          "chR_Due_Installment_No_int": Number(monthInfo.chR_Due_Installment_No_int),
          "chI_Noof_Installments_Int":  Number(monthInfo.chI_Noof_Installments_Int),
          "chR_CollectedAmount_num":  Number(monthInfo.chR_CollectedAmount_num),
          "chI_InstallmentAmount_Int":  Number(String(subSchemeInfo.chI_InstallmentAmount_Int)+"00"),
          "status": monthInfo.status,
          "chittype": monthInfo.chittype,
          "ipAddress": "127.0.0.1",
          "user_id": String(myUserInfo.aU_PK_ID),
          "username": myUserInfo.aU_USERNAME_VAR,
          "currency": "INR"
        }).then((response) => {
          console.log("--- Order Response ")
          console.log(response.data);
          if (response.data.statusCode === "001") {
            console.log("--- Order Created ")
            console.log("--- Payment Started ")
            storeData(scheme.accno, Number(monthInfo.chR_Due_Installment_No_int))
            setShowModal(false);
            var createdOrder = response.data.data;
            var orderAmount = String(subSchemeInfo.chI_InstallmentAmount_Int) + "00"
            var RPOption = {
              description: "Scheme payment",
              image: "",
              currency: "INR",
              key: rpKey.key,
              order_id: createdOrder,
              amount: orderAmount,
              name: subSchemeInfo.accname,
              callback_url: 'https://chit.sktmjewels.com/',
              redirect: false,
              timeout:180,
              prefill: {
                email: myUserInfo.aU_EMAILID_VAR,
                contact: String(myUserInfo.aU_MOBILE_NO_VAR),
                name: subSchemeInfo.accname,
                method: 'card|upi',
              },
              theme: { color: "#F8C005" },
              handler: function (response) {
                console.log("razorpay response",response)
                if(response.razorpay_signature)
                {
                  monthInfo["receiptDate"] = String(new Date().toISOString())
                  fetchDataFromRPAY(response, monthInfo);
                   // props.history.push("/dashboard")
                }
                // alert(response.razorpay_payment_id);
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature);
              },
            };
            const rzpay = new Razorpay(RPOption);
            rzpay.open()
           // useRazorpay.open(RPOption)
              .then((data) => {
                // console.log("--- Payment Success ")
                // setAlert(true)
                // monthInfo["receiptDate"] = String(new Date().toISOString())
                // fetchDataFromRPAY(data, monthInfo);
              })
              .catch(async(error) => {
               console.log("Order id", createdOrder)
                alert(`Error: ${error.code} | ${error.description}`);
               try {
                URLSocket.post("Auth/PayOrder", {
                  order_ID:createdOrder
                })
                .then(response => {
                  console.log("reponse", response.data)
                })
               } catch (error) {
                
               }
                
                if(error.code === 0)
                {
                  alert("Message: Payment was cancelled by user")
                  //removeDataFromAsyncStorage(scheme.accno)
                }
                setDataFetching(true);
                setFetchSummary([]);
                setSchemeSummary([]);
                monthInfo["transaction"] =
                  error.code === "001" ? "Cancelled" : "Declined";
                setTD(monthInfo);
                refreshSummary();
              });
          }
          else
          {
            alert("Message: Something went wrong please try again after sometime")
          }
        })
        .catch(error=>{
          if(error.code === 0)
                {
                  alert("Message: Something went wrong please try again after sometime")
                }
        })
        ;
      } catch (error) {
        alert("Message: Something went wrong please try again after sometime")
      }
    } else {
      // ------ Wallet Mode ---------
    }
  };

  // const checkPayment = async (order_id) => {
  //   try {
  //     const authHeader =
  //       "Basic " +
  //       base64.encode(
  //         `${rpKey.key}:${rpKey.security}` 
  //       );
  //     axios({
  //       method: "get",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: authHeader,
  //       },
  //       url:
  //         "https://api.razorpay.com/v1/orders/"+order_id+"/payments"
  //     })
  //       .then((response) => {
  //         console.log("response", response.data)
  //       })
  //   } catch (error) {
      
  //   }
  // }

  const fetchDataFromRPAY = async (data, monthInfo) => {
    try {
      console.log("--- Sending payment Information to razor pay ")
      const authHeader =
        "Basic " +
        base64.encode(
          `${rpKey.key}:${rpKey.security}` 
        );
      axios({
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
        url:
          "https://api.razorpay.com/v1/payments/" +
          String(data.razorpay_payment_id),
      })
        .then((response) => {
          console.log("--- Payment Information from razor pay ", response.data)
          setDataFetching(true);
          var RPayInfo = response.data;
          console.log("--- Sending Payment information to DB ")
          returnToBase(RPayInfo, monthInfo);
        })
        .catch((error) => {
          console.log("--- getting Payment Information from razor pay failed")
          console.log("catch error", error);
          console.log('Error', error.code);
              if(error.code === "ECONNABORTED")
              {
                setTimeoutEnabled(true)
              }
        });
    } catch (error) {
      console.log("catch error", error);
    }
  };

  const returnToBase = (RPayInfo, monthInfo) => {
    console.log("monthInfo", monthInfo)
    try {
      URLSocket.post("Scheme/InstallmentsPay", {
        payInstallments: monthInfo,
        paymentDetails: RPayInfo,
      },
      )
        .then((response) => {
          console.log("--- Payment Information storing response", response.data)
          if (response.data.statusCode === "001") {
            console.log("--- Payment Information successfully stored in DB")
            setFetchSummary([]);
            setSchemeSummary([]);
            refreshSummary();
          }
        })
        .catch((error) => {
          console.log("--- Failure. Payment Information Not stored in DB ------")
          console.log("catch error", error);
        });
    } catch (error) {
      console.log("catch error", error);
    }
  };

  const getReceipts = (payData) => {
    try {

      URLSocket.post("Scheme/GetReceiptDetails", {
        receiptID: String(payData.recHeaderID),
        location: Number(payData.locid),
        accountNo: payData.accno,
        chitMemberID: Number(payData.chitmemberid),
        mail: myUserInfo.aU_EMAILID_VAR,
      }).then((response) => {
        if (response.data.statusCode === "001") {
          setReceiptData(response.data.data[0]);
          setshowReceipt(true);
        }
      });
    } catch (error) {
      console.log("catch error", error);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Breadcrumbs title={"Scheme Summary"} />
          <Col lg="12">
            <Row className="mb-3">
              <div>
                <Button className="button-secondary mb-3" onClick={()=>{props.history.goBack()}}>Back</Button>
              </div>
              <h3>{scheme.chitname}</h3>
              <div>
                Account Name - <strong>{scheme.accname}</strong> <br /> Account
                Number - <strong>{scheme.accno}</strong>
              </div>
              <div>
                Scheme Type - <strong>{scheme.chittype}</strong>
              </div>
            </Row>
            {showSummary ? (
              <LatestTranaction
                mySchemes={schemeSummary}
                navigateTo={() => {
                  navigateTo();
                }}
                paymentMode={(item, mode)=>{paymentMode(item, mode);}}
              />
            ) : (
              <Row>
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div>Loading summary data. Please wait...</div>
              </Row>
            )}
          </Col>
        </Container>
      </div>
    </React.Fragment>
  );
};

SchemeDetails.propTypes = {
  t: PropTypes.any,
  history: propTypes.any,
};

export default withTranslation()(SchemeDetails);
