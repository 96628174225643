import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table, Badge,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    FormGroup,
    Form,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import { propTypes } from "react-bootstrap-editable";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { isEmpty, size } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

import URLSocket from "helpers/urlSocket";





const NewSchemes = props => {

    const dispatch = useDispatch()
    const [userInfo, setUserInfo] = useState("")
    const { orders } = useSelector(state => state.ecommerce);
    const [myorders, setOrders] = useState(orders)
    const [schemeCategory, setSchemeCategory] = useState([])
    const [subScheme, setSubSchemeList] = useState([])
    const [selectedSchemeInfo, setSelectedScheme] = useState({})
    const [isRight, toggleRightCanvas] = useState(false)

    //console.log("orders", orders)
    const [EcommerceOrderColumns, setEcommerceOrderColumns] = useState([
        {
            dataField: "chI_Name",
            text: "Chit Name",
            sort: true,
        },
        {
            dataField: "chI_InstallmentAmount",
            text: "Installment Amount",
            sort: true,
        },
        {
            dataField: "chI_Noof_Installments",
            text: "No. of Installments",
            sort: true,
        },
        
        
        {
            dataField: "view",
            isDummyField: true,
            text: "View Details",
            sort: true,
            formatter: (cellContent, item) => (
                <Button
                    type="button"
                    color="secondary"
                    className="btn-sm btn-rounded"
                    onClick={() => { selectedScheme(item)}}
                >
                    Select this Scheme
                </Button>
            ),
        }
    ])



    useEffect(() => {
        var userInformation = JSON.parse(localStorage.getItem("authUser"))
        console.log("user", userInformation)
        localStorage.removeItem("schemeInfo")
        setUserInfo(userInformation)
        fetchMainScheme()

    }, [])

    const fetchMainScheme = async () => {
        try {
            URLSocket.get("Scheme/Schemelist").then((response) => {
                if (response.data.statusCode === "001") {
                    setSchemeCategory(response.data.data)
                    console.log("schemelist", response.data.data)
                }
            })
                .catch(error => console.log("catch error", error))
        } catch (error) {
            console.log("catch error", error);
        }
    };

    const fetchSchemeList = (data) => {
        try {
          URLSocket.post("Scheme/subschemelist", {
            schemeID: data.chiT_PK_ID,
          }).then((response) => {
            if (response.data.statusCode === "001") {
                console.log("response.data.", response.data)
              setSubSchemeList(response.data.data);
            }
          })
          .catch(error => {
            console.log("catch error", error);
          })
        } catch (error) {
          console.log("catch error", error);
        }
      };

      


      const selectedScheme = (data) => {

        // scheme-form
        console.log("data", data)
        localStorage.removeItem("schemeInfo")
        localStorage.setItem("schemeInfo", JSON.stringify(data))
        props.history.push("/scheme-form")
        // console.log("data", data)
        // setSelectedScheme(data)
        // toggleRightCanvas(true)
        
      }


    //pagination customization
    const pageOptions = {
        sizePerPage: 25,
        totalSize: subScheme.length, // replace later with size(Order),
        custom: true,
    }

    const defaultSorted = [{
        dataField: 'orderId',
        order: 'desc'
    }];

    const selectRow = {
        mode: 'checkbox',
    };

    if (schemeCategory.length > 0) {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={props.t("New Schemes")}
                        />

                        <Row className="mb-3">
                            {
                                schemeCategory.map((item, idx) => {
                                    return (<div className="mt-1 " key={"btn" + String(idx)} style={{width:"fit-content", margin:0, padding:0, marginLeft:12}}>
                                        <Link
                                            to="#"
                                            className="btn btn-primary btn-sm"
                                            onClick={(e)=>{
                                                e.preventDefault()
                                                fetchSchemeList(item)
                                            }}
                                        ><div>{item.chiT_DESCRIPTION} {" "}</div>
                                            <div className="font-size-14">{item.chiT_NAME_VAR} {" "}<i className="mdi mdi-arrow-right ms-1" /></div>
                                        </Link>
                                    </div>)
                                })
                            }
                        </Row>

                        <Card>
                            <CardBody>
                                <div className="mb-4 h4 card-title">Latest Transaction</div>
                                <PaginationProvider
                                    pagination={paginationFactory(pageOptions)}
                                    keyField='id'
                                    columns={EcommerceOrderColumns}
                                    data={subScheme}
                                >
                                    {({ paginationProps, paginationTableProps }) => (
                                        <ToolkitProvider
                                            keyField="id"
                                            data={subScheme}
                                            columns={EcommerceOrderColumns}
                                            bootstrap4
                                            search
                                        >
                                            {toolkitProps => (
                                                <React.Fragment>
                                                    <div className="table-responsive">
                                                        <BootstrapTable
                                                            {...toolkitProps.baseProps}
                                                            {...paginationTableProps}
                                                            responsive
                                                            defaultSorted={defaultSorted}
                                                            bordered={false}
                                                            striped={false}
                                                            // selectRow={selectRow}
                                                            classes={
                                                                "table align-middle table-nowrap table-check"
                                                            }
                                                            headerWrapperClasses={"table-light"}
                                                        />
                                                    </div>
                                                    <div className="pagination pagination-rounded justify-content-end">
                                                        <PaginationListStandalone
                                                            {...paginationProps}
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </ToolkitProvider>
                                    )}
                                </PaginationProvider>
                            </CardBody>
                        </Card>
                        <Offcanvas
                      isOpen={isRight}
                      direction="end"
                      toggle={()=>toggleRightCanvas(isRight ? false : true)}
                    >
                      <OffcanvasHeader toggle={()=>toggleRightCanvas(false)}>
                       Scheme
                      </OffcanvasHeader>
                      <OffcanvasBody>
                      {/* <SchemeForm 
                            schemeInfo={selectedSchemeInfo}
                        /> */}
                        <Container fluid>
                            
                            <Row>
                            <h4 className="card-title">{selectedSchemeInfo.chI_Name}</h4>
                    <p className="card-title-desc">
                      Provide valuable, actionable feedback to your users with
                      HTML5 form validation–available in all our supported
                      browsers.
                    </p>
                    

                            </Row>
    
                   
                        </Container>
                      </OffcanvasBody>
                    </Offcanvas>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
    else {
        return null
    }



}

NewSchemes.propTypes = {
    t: PropTypes.any,
    history: propTypes.any
};

export default withTranslation()(NewSchemes);