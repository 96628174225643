import React, { Component } from "react";
import { Container } from "reactstrap";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

class Terms extends Component {
  render() {
    //meta title
    document.title = "Starter Page | Skote - React Admin & Dashboard Template";
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="" breadcrumbItem="Terms and Conditions" />
            <Row className="align-items-center">
              <Col lg="12">
                <div className="text-muted">
                  <ul>
                    <li
                      style={{
                        color: "#666666",
                        textAlign: "justify",
                        marginBottom: 8,
                      }}
                    >
                      1. For enrolling online Purchase Plan Payment system, a
                      customer should have valid mobile number instalments,
                      Duration, Benefits and Terms would be varied in accordance
                      with the scheme. To know about each schemes kindly visit
                      respective sections. {"\n"}{" "}
                    </li>
                    <li
                      style={{
                        color: "#666666",
                        textAlign: "justify",
                        marginBottom: 8,
                      }}
                    >
                      2. Online payment status would be updated to the customer,
                      once settlement done from payment gateway and such delay
                      varies based on payment mode and concern banks. {"\n"}{" "}
                    </li>
                    <li
                      style={{
                        color: "#666666",
                        textAlign: "justify",
                        marginBottom: 8,
                      }}
                    >
                      3. Online payment system users can get the membership card
                      at showroom. {"\n"}{" "}
                    </li>
                    <li
                      style={{
                        color: "#666666",
                        textAlign: "justify",
                        marginBottom: 8,
                      }}
                    >
                      4. On completion of Plan, Customer can purchase Jewellery
                      at Showroom only. {"\n"}{" "}
                    </li>
                    <li
                      style={{
                        color: "#666666",
                        textAlign: "justify",
                        marginBottom: 8,
                      }}
                    >
                      5. The Company reserves the right to after, amend, add or
                      delete part or whole of the privileges of the Plan without
                      prior notice. {"\n"}{" "}
                    </li>
                    <li
                      style={{
                        color: "#666666",
                        textAlign: "justify",
                        marginBottom: 8,
                      }}
                    >
                      6. The monthly Payment must be paid for 11 months. {"\n"}{" "}
                    </li>
                    <li
                      style={{
                        color: "#666666",
                        textAlign: "justify",
                        marginBottom: 8,
                      }}
                    >
                      7. 5% discount only for Diamond (precious stone). {"\n"}{" "}
                    </li>
                    <li
                      style={{
                        color: "#666666",
                        textAlign: "justify",
                        marginBottom: 8,
                      }}
                    >
                      8. Minors (below 18 years) will not be eligible to enrol.{" "}
                      {"\n"}{" "}
                    </li>
                    <li
                      style={{
                        color: "#666666",
                        textAlign: "justify",
                        marginBottom: 8,
                      }}
                    >
                      9. Customer have to submit copies of ID/Address Proof
                      along with the originals at the time of joining the plan.
                      In case of changes in customer’s names, address, etc. the
                      management should be kept well informed duly supported by
                      the relevant proofs / documents to respective branch where
                      the account opened without any loss of time. {"\n"}{" "}
                    </li>
                    <li
                      style={{
                        color: "#666666",
                        textAlign: "justify",
                        marginBottom: 8,
                      }}
                    >
                      10. All disputes are subject to chennai jurisdiction.{" "}
                      {"\n"}{" "}
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Terms;
