import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Badge,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  FormGroup,
  Form,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import { propTypes } from "react-bootstrap-editable";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isEmpty, size } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";
import URLSocket from "helpers/urlSocket";



import LatestTranaction from "./Components/LatestTranaction";

const MySchemes = (props) => {

  const [modal, setmodal] = useState(false);
  const [subscribemodal, setSubscribemodal] = useState(false);
  const [userInfo, setUserInfo] = useState("")
  const [error, setError] = React.useState({
    message: "",
    isError: false,
  });
  const [mySchemes, setMySchemes] = useState([]);
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    var userInformation = JSON.parse(localStorage.getItem("authUser"))
    console.log("user", userInformation)
    setUserInfo(userInformation)
    
  }, []);

  useEffect(()=>{
    if(userInfo !== "")
    {
      fetchSchemeInfo()
    }

  },[userInfo])

  const fetchSchemeInfo = () => {
    try {
      console.log("userInfo.aU_MOBILE_NO_VAR", userInfo.aU_MOBILE_NO_VAR)
      URLSocket.post("Scheme/dashboard", {
        mobileNo: String(userInfo.aU_MOBILE_NO_VAR),
        location: 0,
      }).then((response) => {
        if (response.data.statusCode === "001") {
          console.log("scheme data", response.data)
          setMySchemes(response.data.data);
          setLoaded(true)
         //setRefreshing(false);
          setError({ isError: false, message: "" });
        } else {
          setError({ isError: true, message: "No Records found" });
        }
      })
        .catch(error => {
          //setRefreshing(false);
          setError({ isError: true, message: "Something went wrong. Please try again after sometime." });
        })
    } catch (error) {
      console.log("catch error", error);
      //setRefreshing(false);
      setError({ isError: true, message: "Something went wrong. Please try again after sometime." });
    }
  };

  const navigateTo = (item) => {
    localStorage.removeItem("schemeInfo")
    localStorage.setItem("schemeInfo", JSON.stringify(item))
    props.history.push("/scheme-details")
  }

  if(loaded)
  {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={""} />
          <Row>
            <Col lg="12">
              <LatestTranaction 
              mySchemes = {mySchemes}
              navigateTo = {(data)=>{navigateTo(data)}}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
  }
  else{
    return null
  }
};

MySchemes.propTypes = {
  t: PropTypes.any,
  history: propTypes.any,
};

export default withTranslation()(MySchemes);
