import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table, Form, Label
} from "reactstrap";
import { Link } from "react-router-dom";
import { propTypes } from "react-bootstrap-editable";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";
import ExistSchemeList from "./Components/existSchemeList";



import URLSocket from "../../helpers/urlSocket";
import RPCode from "../../helpers/rpcode"
import useRazorpay from "react-razorpay";

const ExistingSchemes = props => {

    const [myUserInfo, setUserInfo] = useState("")
    const [branchList, setBranchList] = useState([]);
    const [branchError, setBranchError] = useState(false)
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [branchId, setBranchId] = React.useState("choose");

    const [mySchemes, setMySchemes] = React.useState([]);
    const [checkInfo, setCheckInfo] = React.useState(false);
    const [checkSchemeInfo, setCheckSchemeInfo] = React.useState(false)
  
  



    useEffect(() =>{
        var userInformation = JSON.parse(localStorage.getItem("authUser"))
        console.log("user", userInformation)
        setUserInfo(userInformation)
        
    },[])

    useEffect(() => {
        if(myUserInfo !== "")
        {
            fetchBranches();
        }
    }, [myUserInfo ])

    const fetchBranches = async () => {
        try {
            URLSocket.get("Scheme/Branchlist").then((response) => {
                if (response.data.statusCode === "001") {
                    setBranchList(response.data.data);
                    setError({ isError: false, message: "" });
                } else {
                    setError({ isError: true, message: "No Records found" });
                }
            });
        } catch (error) {
            console.log("catch error", error);
        }
    };
    const submitBranchInfo = () => {
        console.log("branch", branchId, phoneNumber);
        setCheckInfo(true);
        setMySchemes([])
        try {
          URLSocket.post("Scheme/listexistingscheme", {
            mobileNo: myUserInfo.aU_MOBILE_NO_VAR,
            location: branchId,
          })
            .then((response) => {
              console.log("response", response.data);
              if (response.data.statusCode === "001") {
                setMySchemes(response.data.data);
                setError({ isError: false, message: "" });
                //console.log("response", response.data);
                //console.log("res", response.data);
              } else {
                setMySchemes([]);
                setError({ isError: true, message: "No Records found" });
              }
              setCheckInfo(false);
            })
            .catch((error) => {
              setCheckInfo(false);
              setError({
                isError: true,
                message: "Something went wrong. Please try again after sometime.",
              });
            });
        } catch (error) {
          console.log("catch error", error);
        }
      };
    
      const addScheme = (data) => {
        console.log(
          "branch",
          data.mobileno,
          data.locid,
          data.accno,
          data.chitmemberid
        );
        setCheckSchemeInfo(true)
        try {
          URLSocket.post("Scheme/addmyscheme", {
            mobileNo: data.mobileno,
            location: data.locid,
            accountNo: data.accno,
            chitMemberID: data.chitmemberid,
          }).then((response) => {
            console.log("response my scheme", response.data);
            if (response.data.statusCode === "001") {
              submitBranchInfo();
              setCheckSchemeInfo(false)
            } else {
              setMySchemes([]);
              setCheckSchemeInfo(false)
              setError({ isError: true, message: "No Records found" });
            }
          });
        } catch (error) {
          console.log("catch error", error);
        }
      };
    

    

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <Breadcrumbs title="" breadcrumbItem="Existing Schemes" />
                <Row>
                    <Col className="col-2" style={{height:"84vh",borderRight:"1px solid #dedede"}}>
                    <Form >

                    <Row>
                                <Col lg={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="formrow-InputState">Branch</Label>
                                        <select
                                            id="formrow-InputState"
                                            className="form-control"
                                            onChange={(event)=>{
                                                setBranchId(event.target.value)
                                            }}
                                            value={branchId}
                                        >
                                            <option defaultValue disabled value={"choose"}>Choose...</option>
                                            {
                                                branchList.map((item, idx) => {
                                                    return (<option key={"bopt" + String(idx)} value={Number(item.id)}>{item.brancH_NAME}</option>)
                                                })
                                            }

                                        </select>
                                        {
                                            branchError && <Label className="mt-2 text-danger">Select Branch</Label>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            


                            <div>
                                <button type="submit" className="btn btn-primary w-md"
                                 onClick={(event)=>{
                                    event.preventDefault()
                                    submitBranchInfo()
                                }}
                                 >
                                    Submit
                                </button>
                            </div>
                        </Form>
                    </Col>
                    <Col className="col-10">
                        <ExistSchemeList 
                        mySchemes = {mySchemes}
                        addScheme={(item) => {addScheme(item)}}
                        navigateTo = {(data)=>{}}
                        
                        />
                    </Col>
                </Row>

                </Container>
            </div>
        </React.Fragment>
    )

}

ExistingSchemes.propTypes = {
    t: PropTypes.any,
    history: propTypes.any
};

export default withTranslation()(ExistingSchemes);