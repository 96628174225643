import React, { Component } from "react";
import { Container } from "reactstrap";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

class Aboutus extends Component {
  render() {
    //meta title
    document.title = "Starter Page | Skote - React Admin & Dashboard Template";
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="" breadcrumbItem="About us" />

            <Row className="align-items-center">
              <Col lg="12">
                <div className="text-muted">
                  <h4>About</h4>
                  <p>
                    <strong>Sree Kumaran Thangamaligai</strong> a group company
                    of <strong>“The Chennai Silks group”</strong> since 1962, we
                    upholds the visionary values of our founder Shri. A
                    Kulandaivel Mudaliar, who envisioned a bright future, one
                    that would transcend time and for generations to come. It
                    was his vision that revolutionized a humble weaving unit
                    into a business conglomerate with diverse business interests
                    as what we are today. His extraordinary spirit continues to
                    inspire us to scale greater heights of success in all our
                    endeavors. {"\n"}
                    {"\n"}
                    Sree Kumaran Thangamaligai (The Madras Silks India Private
                    Limited) is a shopper’s paradise for exquisitely crafted
                    imported jewellery. SKTM is fast emerging as preferred
                    destination for quality jewellery showcasing the fine
                    creations from classic designs that reflects the convergence
                    of traditional collections to modern artistic Jewellery in
                    gold, diamond, platinum, silver and other precious stone
                    ornaments with low labour charges affordable low wastage
                    cost. We are familiar for our attractive light weight jewel
                    design collections. You can even get the jewel customized by
                    placing order according to specifications for those of your
                    special occasions in life. {"\n"}
                    {"\n"} <br />
                    <br />
                    At Present, we have 36 world class reputed showrooms across
                    South India located at {"\n"}Chennai (T.Nagar, Velachery,
                    Chrompet and Thiruvallur), {"\n"}Coimbatore, Tirupur, Erode,
                    Trichy, Karur, Tirunelveli, Namakkal, Ooty, Madurai, Salem,
                    Vellore, Villupuram, Hosur, Udumalpet, Kumbakonam,
                    Tuticorin, Hyderabad, Thanjavur, Mettupalayam, Rajapalayam,
                    Mehdipatnam, Bhavani, Viruthachalam, Puthukottai,
                    Krishnagiri, Sivakasi, Puducherry, Nagerkoil, Theni,
                    Tuticorin, Cumbam, Thenkasi. {"\n"}
                    {"\n"}
                  </p>
                  <p className="mb-4">
                    It would be necessary to have uniform pronunciation.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Aboutus;
