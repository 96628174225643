import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import avatar1 from "../../assets/images/users/avatar-1.jpg";
import profileImg from "../../assets/images/profile-img.png";


class WelcomeComp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userInfo:{}
    }

  }

  componentDidMount(){
    var userInformation = JSON.parse(localStorage.getItem("authUser"))
    console.log("user", userInformation)
    this.setState({userInfo:userInformation})
  }

  render() {
    return (
      <React.Fragment>
        <Card className="overflow-hidden">
          <div className="bg-primary bg-soft">
            <Row>
              {/* <Col xs="7">
                <div className="text-primary p-3">
                  <h5 className="text-primary">Swarna Dharaa Welcomes you!</h5>
                </div>
              </Col> */}
              <Col xs="12" className="align-self-end">
                <img src={'https://sktmonline.com/images/slide03_new.jpg'}  alt="" className="img-fluid" />
              </Col>
            </Row>
          </div>
          <CardBody className="pt-0">
            <Row>
              <Col sm="4">
                <div className="avatar-md profile-user-wid mb-4">
                  <img
                    src={avatar1}
                    alt=""
                    className="img-thumbnail rounded-circle"
                  />
                </div>
                <h5 className="font-size-15 text-truncate">{this.state.userInfo.aU_FIRSTNAME_VAR}</h5>
                <div className="mt-1">
                    <Link
                      to=""
                      className="btn btn-primary btn-sm"
                    >
                      View Profile {" "}<i className="mdi mdi-arrow-right ms-1"/>
                    </Link>
                  </div>
                {/* <p className="text-muted mb-0 text-truncate">UI/UX Designer</p> */}
              </Col>

              
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

export default WelcomeComp