import React, { Component } from "react";
import { Container } from "reactstrap";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

class CustomerSupport extends Component {
  render() {
    //meta title
    document.title = "Starter Page | Skote - React Admin & Dashboard Template";
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="" breadcrumbItem="Customer support" />

            <Row className="align-items-center">
              <Col lg="12">
                <div className="mt-4 mt-md-auto">
                  <div className="d-flex align-items-center mb-1">
                    <h4 className="mb-0 text-primary">Registered Address</h4>
                  </div>
                  <div className="text-muted mt-1">
                    <p>
                      The Madras Silks India Pvt. Ltd., <br />
                      68A, College Road <br />
                      Tirupur - 641602 <br />
                    </p>
                  </div>
                </div>
              </Col>

              <Col lg="12">
                <div className="mt-4 mt-md-auto">
                  <div className="d-flex align-items-center mb-1">
                    <h4 className="mb-0 text-primary">Communication Address</h4>
                  </div>
                  <div className="text-muted mt-1">
                    <p>
                      Sree Kumaran Thangamaligai
                      <br />
                      55, Usman Road, <br />
                      T.Nagar, Chennai - 600017
                      <br />
                    </p>
                  </div>
                </div>
              </Col>

              <Col lg="12">
                <div className="mt-4 mt-md-auto">
                  <div className="d-flex align-items-center mb-1">
                    <h4 className="mb-0">Phone</h4>
                  </div>
                  <div className="text-muted mt-1">
                    <p>
                      +91 44 2433 0888 <br /> +91 44 2432 1244
                    </p>
                  </div>
                </div>
              </Col>

              <Col lg="12">
                <div className="mt-4 mt-md-auto">
                  <div className="d-flex align-items-center mb-1">
                    <h4 className="mb-0">Mobile</h4>
                  </div>
                  <div className="text-muted mt-1">
                    <p>+91 95662 80503</p>
                  </div>
                </div>
              </Col>

              <Col lg="12">
                <div className="mt-4 mt-md-auto">
                  <div className="d-flex align-items-center mb-1">
                    <h4 className="mb-0">Email</h4>
                  </div>
                  <div className="text-muted mt-1">
                    <p>sktmhelpdesk@gmail.com</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default CustomerSupport;
