import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";

import classNames from "classnames";

//import Charts
import StackedColumnChart from "./StackedColumnChart";

//import action
import { getChartsData as onGetChartsData } from "../../store/actions";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";
import img1 from "../../assets/sktm_images/saving.png"
import img2 from "../../assets/sktm_images/gold.png"

// Pages Components
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";
import SocialSource from "./SocialSource";
import ActivityComp from "./ActivityComp";
import TopCities from "./TopCities";
import LatestTranaction from "./LatestTranaction";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

import URLSocket from "helpers/urlSocket";

const Dashboard = props => {
  const [modal, setmodal] = useState(false);
  const [subscribemodal, setSubscribemodal] = useState(false);
  const [userInfo, setUserInfo] = useState("")
  const [error, setError] = React.useState({
    message: "",
    isError: false,
  });
  const [mySchemes, setMySchemes] = useState([]);
  const [loaded, setLoaded] = useState(false)

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }));

  const reports = [
    { title: "Orders", iconClass: "bx-copy-alt", description: "1,235" },
    { title: "Revenue", iconClass: "bx-archive-in", description: "$35, 723" },
    {
      title: "Average Price",
      iconClass: "bx-purchase-tag-alt",
      description: "$16.2",
    },
  ];

  useEffect(() => {
    var userInformation = JSON.parse(localStorage.getItem("authUser"))
    console.log("user", userInformation)
    setUserInfo(userInformation)
    
  }, []);

  useEffect(()=>{
    if(userInfo !== "")
    {
      fetchSchemeInfo()
    }

  },[userInfo])

  const [periodData, setPeriodData] = useState([]);
  const [periodType, setPeriodType] = useState("yearly");

  useEffect(() => {
    setPeriodData(chartsData);
  }, [chartsData]);

  const onChangeChartPeriod = pType => {
    setPeriodType(pType);
    dispatch(onGetChartsData(pType));
  };

  const dispatch = useDispatch();
  

  const fetchSchemeInfo = () => {
    try {
      console.log("userInfo.aU_MOBILE_NO_VAR", userInfo.aU_MOBILE_NO_VAR)
      URLSocket.post("Scheme/dashboard", {
        mobileNo: String(userInfo.aU_MOBILE_NO_VAR),
        location: 0,
      }).then((response) => {
        if (response.data.statusCode === "001") {
          console.log("scheme data", response.data)
          setMySchemes(response.data.data);
          setLoaded(true)
         //setRefreshing(false);
          setError({ isError: false, message: "" });
        } else {
          setError({ isError: true, message: "No Records found" });
        }
      })
        .catch(error => {
          //setRefreshing(false);
          setError({ isError: true, message: "Something went wrong. Please try again after sometime." });
        })
    } catch (error) {
      console.log("catch error", error);
      //setRefreshing(false);
      setError({ isError: true, message: "Something went wrong. Please try again after sometime." });
    }
  };

  const navigateTo = (item) => {
    localStorage.removeItem("schemeInfo")
    localStorage.setItem("schemeInfo", JSON.stringify(item))
    props.history.push("/scheme-details")
  }


  //meta title
  document.title="Dashboard | Skote - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            <Col xl="12">
              <WelcomeComp />
            </Col>
            <Col xl="8">
              <Row>
                {/* Reports Render */}
                <Col md="6" >
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex" style={{cursor:"pointer"}} onClick={()=>{
                          props.history.push("/new-schemes")
                        }}>
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Join New Schemes
                            </p>
                          </div>
                          <div>
                          <img src={img2} height={50}></img>
                          </div>
                         
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6" >
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex" style={{cursor:"pointer"}} onClick={()=>{
                          props.history.push("/exisiting-schemes")
                        }}>
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Existing Schemes
                            </p>
                          </div>
                          <div>
                          <img src={img1} height={50}></img>
                          </div>
                         
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
              </Row>

            </Col>
          </Row>

          <Row>
            <Col lg="12">
              {
                  loaded && 
                  <LatestTranaction 
                    mySchemes = {mySchemes}
                    navigateTo = {(data)=>{navigateTo(data)}}
                    />
              }
              
            </Col>
          </Row>
        </Container>
      </div>

    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
  history: PropTypes.any
};

export default withTranslation()(Dashboard);
