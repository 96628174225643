import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { isEmpty, size } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import { Link } from "react-router-dom"

import { Button, Card, CardBody, Badge } from "reactstrap"


import EcommerceOrdersModal from "../../Ecommerce/EcommerceOrders/EcommerceOrdersModal"

import moment from "moment"

class ChitSummary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      viewmodal: false,
      modal: false,
      orders: [],
      EcommerceOrderColumns: [
        // {
        //   text: "id",
        //   dataField: "id",
        //   sort: true,
        //   hidden: true,
        //   formatter: (cellContent, user) => (
        //     <>
        //       {order.id}
        //     </>
        //   ),
        // },
        {
          dataField: "installment",
          text: "Installment",
          sort: true,
          formatter: (cellContent, row) => (
            <Link to="#" className="text-body fw-bold font-size-20">
              {row.installment}
            </Link>
          ),
        },
        {
          dataField: "paid_on",
          text: "Paid on",
          sort: true,
          formatter: (cellContent, row) => (
            <div
              style={{
                color:
                  row.payment_status === "0" || row.payment_status === "2"
                    ? "#666666"
                    : "#FFB100",
              }}
            >
              {row.payment_status === "0" || row.payment_status === "2"
                ? "Yet to pay"
                : row.paid_on}
            </div>
          ),
        },
        {
          dataField: "amount",
          text: "Amount",
          sort: true,
          formatter: (cellContent, row) => (
            <div
              style={{
                color:
                  row.payment_status === "0" || row.payment_status === "2"
                    ? "#666666"
                    : "#FFB100",
                fontWeight: "600",
              }}
            >
              {row.amount}
            </div>
          ),
        },

        {
          dataField: "totalamount",
          text: "Total Amount",
          sort: true,
          formatter:(cellContent, row) => (
            <div>
              {row.payment_status === "0" ? (
              <div>
                <button
                  style={{
                    width: 80,
                    height: 40,
                    borderRadius: 5,
                    backgroundColor: row.disabled
                      ? "grey"
                      : "#FFB100",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  disabled={row.disabled}
                  onClick={() => {
                    props.paymentMode(row, "Online");
                    //setPaymentModal(true);
                  }}
                >
                  <span
                    style={{
                      fontSize: 12,
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    {props.row ? "In process" : "Pay now"}
                  </span>
                </button>
              </div>
            ) : row.payment_status === "1" ? (
              <div>
                <Button className="button-secondary"
                onClick={() => {
                  this.props.getReceipts();
                }}>Receipt</Button>

              </div>
            ) : null}
            </div>
            
          )
        },
        
      ],
    };
    this.toggle = this.toggle.bind(this)
    this.toLowerCase1 = this.toLowerCase1.bind(this)
  }

  toLowerCase1(str) {
    return str.toLowerCase();
  }

  componentDidMount() {
    // const { orders, onGetOrders } = this.props
    // if (orders && !orders.length) {
    //   onGetOrders()
    // }
    // this.setState({ orders })
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    // const { orders } = this.props
    // if (!isEmpty(orders) && size(prevProps.orders) !== size(orders)) {
    //   this.setState({ orders: {}, isEdit: false })
    // }
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }


  toggleViewModal = () => {
    this.setState(prevState => ({
      viewmodal: !prevState.viewmodal,
    }))
  }
  render() {
    console.log("this.props.mySchemes", this.props.mySchemes)

    //pagination customization
    const pageOptions = {
      sizePerPage: 20,
      totalSize: this.props.mySchemes.length, // replace later with size(Order),
      custom: true,
    }

    const defaultSorted = [{
      dataField: 'orderId',
      order: 'desc'
    }];

    const selectRow = {
      mode: 'checkbox',
    };

    return (
      <React.Fragment>
        <EcommerceOrdersModal
          isOpen={this.state.viewmodal}
          toggle={this.toggleViewModal}
        />
        <Card>
          <CardBody>
            {
              this.props.mySchemes.length > 0 ? <div className="mb-4 h4 card-title">Transactions</div> : <div className="mb-4 h4 card-title">No Transactions</div>
            }

            {
              this.props.mySchemes.length > 0 ?
              <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField='id'
              columns={this.state.EcommerceOrderColumns}
              data={this.props.mySchemes}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  data={this.props.mySchemes}
                  columns={this.state.EcommerceOrderColumns}
                  bootstrap4
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <div className="table-responsive">
                        <BootstrapTable
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                          responsive
                          defaultSorted={defaultSorted}
                          bordered={false}
                          striped={false}
                          // selectRow={selectRow}
                          classes={
                            "table align-middle table-nowrap table-check"
                          }
                          headerWrapperClasses={"table-light"}
                        />
                      </div>
                      <div className="pagination pagination-rounded justify-content-end">
                        <PaginationListStandalone
                          {...paginationProps}
                        />
                      </div>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>:
            null
            }
            
            
            
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

// LatestTranaction.propTypes = {
//   orders: PropTypes.array,
//   onGetOrders: PropTypes.func,
//   onAddNewOrder: PropTypes.func,
//   onDeleteOrder: PropTypes.func,
//   onUpdateOrder: PropTypes.func
// }

// const mapStateToProps = state => ({
//   orders: state.ecommerce.orders,
// })

// const mapDispatchToProps = dispatch => ({
//   onGetOrders: () => dispatch(getOrders()),
//   onAddNewOrder: order => dispatch(addNewOrder(order)),
//   onUpdateOrder: order => dispatch(updateOrder(order)),
//   onDeleteOrder: order => dispatch(deleteOrder(order)),
// })

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withRouter(LatestTranaction))

export default ChitSummary