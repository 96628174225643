import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table, Badge,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    FormGroup,
    Form,
    Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import { propTypes } from "react-bootstrap-editable";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { isEmpty, size } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

import URLSocket from "../../helpers/urlSocket";
import RPCode from "../../helpers/rpcode"
import useRazorpay from "react-razorpay";





const SchemeForm = props => {

    const [selectedSchemeInfo, setSelectedScheme] = useState("")
    const [branchList, setBranchList] = useState([]);
    const [accountname, setAccountName] = useState("")
    const [selectedBranch, setBranch] = useState("choose");
    const [referCode, setReferCode] = useState("");
    const [selectedPaymentMode, setPaymentMode] = useState("choose");
    const [errors, setError] = useState("")
    const [rpKey, setRPKey] = useState(RPCode.getKeyCode())

    const [actNameError, setactNameError] = useState(false)
    const [branchError, setBranchError] = useState(false)
    const [pmtError, setPmtError] = useState(false)

    const [userInfo, setUserInfo] = useState("")

    const Razorpay = useRazorpay();


    useEffect(() => {
        var userInformation = JSON.parse(localStorage.getItem("authUser"))
        console.log("user", userInformation)
        setUserInfo(userInformation)

        var getSchemeInfo = JSON.parse(localStorage.getItem("schemeInfo"))
        console.log('getSchemeInfo', getSchemeInfo)
        setSelectedScheme(getSchemeInfo)
        fetchBranches()
    }, [])

    const fetchBranches = async () => {
        try {
            URLSocket.get("Scheme/Branchlist").then((response) => {
                if (response.data.statusCode === "001") {
                    setBranchList(response.data.data);
                    setError({ isError: false, message: "" });
                } else {
                    setError({ isError: true, message: "No Records found" });
                }
            });
        } catch (error) {
            console.log("catch error", error);
        }
    };


    const validate = () => {

        if(accountname.length === "" || accountname.length < 3 ) { setactNameError(true) } else { setactNameError(false) }
        if(selectedBranch === "choose") { setBranchError(true) } else { setBranchError(false) }
        if(selectedPaymentMode === "choose") { setPmtError(true) } else { setPmtError(false) }
        if(referCode === "" ) {setReferCode("1000") } else {}

        if(!actNameError && !branchError && !pmtError)
        {
            return true
        }
        else
        {
            return false
        }

    }

    const onSubmitData = (e) => {
        e.preventDefault()
        console.log("validate", validate())
        if(validate())
        {
            URLSocket.post("Scheme/NewSchemeOrderCreate", {
                "amount": String(selectedSchemeInfo.chI_InstallmentAmount) + "00",
                "currency": "INR",
                "receipt": "null",
                "username": userInfo.aU_USERNAME_VAR,
                "mobileNo": String(userInfo.aU_MOBILE_NO_VAR),
                "accountName": accountname,
                "schemeName": selectedSchemeInfo.chI_Name,
                "schemetype": String(selectedSchemeInfo.chI_Type_ID),
                "ipAddress": "127.0.0.1",
                "user_id": Number(userInfo.aU_PK_ID),
                "locationid": Number(selectedBranch),
                "tototal_Installments": Number(selectedSchemeInfo.chI_Noof_Installments),
                "referalCode": Number(referCode),
    
              })
                .then((response) => {
                    console.log("response", response.data)
                    if (response.data.statusCode === "001") {
                        var createdOrder = response.data.data;
                        var options = {
                            description: "New Scheme",
                            image: "",
                            currency: "INR",
                            key: rpKey.key,
                            // callback_url: 'http://localhost:3000/scheme-form',
                            redirect: true,
                            order_id: createdOrder,
                            amount: String(selectedSchemeInfo.chI_InstallmentAmount) + "00",
                            name: accountname,
                            prefill: {
                                email: userInfo.aU_EMAILID_VAR,
                                contact: String(userInfo.aU_MOBILE_NO_VAR),
                                name: accountname,
                            },
                            theme: { color: "#F8C005" },
                            handler: function (response) {
                                if(response.razorpay_signature)
                                {
                                    props.history.push("/dashboard")
                                }
                                // alert(response.razorpay_payment_id);
                                // alert(response.razorpay_order_id);
                                // alert(response.razorpay_signature);
                              },
                        };
                        const rzpay = new Razorpay(options);
                        rzpay.open()
                        .then((response) => {
                            // handle success
                            console.log(`Success: ${response.razorpay_payment_id}`);
                        })
                        .catch(error => {
                            console.log("razor pay error", error)
                            if (error.code === 0) {
                            console.log("Message: Payment was cancelled by user")
                            }
                        })
                    }
                })
                .catch(error => {
                    console.log("catch error", error)
                })
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container >
                    <Breadcrumbs
                        title={selectedSchemeInfo.chI_Name}
                    />
                    <Row>
                        <Form >
                            <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">Account name</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-firstname-Input"
                                    placeholder="Account Name"
                                    value={accountname}
                                    onChange={(event)=>{setAccountName(event.target.value)}}
                                />
                                {
                                    actNameError && <Label className="mt-2 text-danger">Enter Account Name and it should contain more than 3 character</Label>
                                }
                            </div>

                            <Row>
                                <Col lg={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="formrow-InputState">Branch</Label>
                                        <select
                                            id="formrow-InputState"
                                            className="form-control"
                                            onChange={(event)=>{
                                                setBranch(event.target.value)
                                            }}
                                            value={selectedBranch}
                                        >
                                            <option defaultValue disabled value={"choose"}>Choose...</option>
                                            {
                                                branchList.map((item, idx) => {
                                                    return (<option key={"bopt" + String(idx)} value={String(item.id)}>{item.brancH_NAME}</option>)
                                                })
                                            }

                                        </select>
                                        {
                                            branchError && <Label className="mt-2 text-danger">Select Branch</Label>
                                        }
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="formrow-refercode">Refer Code</Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="formrow-refercode"
                                            placeholder="Refer Code"
                                            value={referCode}
                                            onChange={(event)=>{setReferCode(event.target.value)}}
        
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="formrow-amount">Amount</Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="formrow-amount"
                                            placeholder="Enter Amount"
                                            value={selectedSchemeInfo.chI_InstallmentAmount}
                                            disabled={true}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="formrow-InputState">Payment</Label>
                                        <select
                                            id="formrow-payment"
                                            className="form-control"
                                            onChange={(event)=>{
                                                setPaymentMode(event.target.value)
                                            }}
                                            value={selectedPaymentMode}
                                        >
                                            <option defaultValue disabled value={"choose"}>Choose...</option>
                                            <option value={"Online"}>Online</option>
                                        </select>
                                        {
                                            pmtError && <Label className="mt-2 text-danger">Select Payment Mode</Label>
                                        }
                                    </div>
                                </Col>
                            </Row>


                            <div>
                                <button type="submit" className="btn btn-primary w-md" onClick={(event)=>{onSubmitData(event)}}>
                                    Submit
                                </button>
                            </div>
                        </Form>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}



SchemeForm.propTypes = {
    t: PropTypes.any,
    history: propTypes.any
};

export default withTranslation()(SchemeForm);